import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/login/login",
    method: "post",
    data,
  });
}

export function logout(data) {
  return request({
    url: "/task/data",
    method: "post",
    data,
  });
}

export function getTaskData(data) {
  return request({
    url: "/task/data",
    method: "post",
    data: data ? data : {},
  });
}
